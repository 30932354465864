$('li.menu-item-has-children > a').each(function () { 
  $(this).attr('href', '#');
});

$('ul.sub-menu').addClass('menu-hide');

if ($(window).width() < 960) {
  $('li.menu-item-has-children > a').on('click', function () {
    $('ul.sub-menu.menu-show').stop(true, true).delay(100).toggleClass('menu-show menu-hide');
    $(this).siblings('ul.sub-menu').stop(true, true).delay(100).toggleClass('menu-hide menu-show');
  });
  return null;
}

$('li.menu-item-has-children > a').on('mouseenter', function(){
  $('ul.sub-menu').stop(true,true).delay(100).removeClass('menu-show').addClass('menu-hide');
  $(this).siblings('ul.sub-menu').stop(true,true).delay(100).removeClass('menu-hide').addClass('menu-show');
});

$('li.menu-item-has-children > a').on('mouseleave', function(){
  if (!$(this).siblings('ul.sub-menu').is(":hover")) {
    $(this).siblings('ul.sub-menu').stop(true,true).delay(100).removeClass('menu-show').addClass('menu-hide');
  }
});

$('ul.sub-menu').on('mouseleave', function(){
  $(this).stop(true,true).delay(100).removeClass('menu-show').addClass('menu-hide');
});
