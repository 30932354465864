// scroll to anchor usually to sections
// from items in menu
const scrollToAnchorItems = [
  ...document.querySelectorAll('.ig-scroll-to-anchor'),
];

const headerOffset = 100;
const scrollToAnchor = (item, topOffset = 80) => {
  item.addEventListener('click', (e) => {
    e.preventDefault();
    const hashUrl = item.getAttribute('href');
    const hash = `#${hashUrl.split('#')[1]}` || hashUrl;
    const target = document.querySelector(hash);
    const elementPosition = target.offsetTop;
    const offsetPosition = elementPosition - topOffset;

    window.scrollTo({
      top: offsetPosition,
      // top: 0,
      behavior: 'smooth',
    });
  });
};

if (scrollToAnchorItems) {
  document.querySelector('html').style.scrollBehavior = 'smooth';
  scrollToAnchorItems.forEach((item) => {
    scrollToAnchor(item, headerOffset);
  });
}
