// const baeToggle = document.querySelector('.bae-navbar-toggle');
// const baeNav = document.querySelector('.bae-nav');

// console.log('loaded');
// if (baeToggle) {
//   baeToggle.addEventListener('click', () => {
//     console.log('bae click');
//     baeNav.classList.toggle('show');
//   })
// }
