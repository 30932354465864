// handle navbar open close
console.log('test');
const baeNav = document.querySelector('.bae-nav');
if (baeNav && baeNav !== null) {
  const baeToggle = document.querySelector('.bae-navbar-toggle');
  const baeNavLinks = baeNav !== null && [...baeNav.querySelectorAll('a')];

  const baeHandleToggle = () => {

    baeToggle.addEventListener('click', () => {
      baeNav.classList.toggle('show');
    })
    baeNavLinks.forEach((item) => {
      if (window.innerWidth <= 768) {
        item.addEventListener('click', () => {
          baeNav.classList.toggle('show');
        })
      }
    });
  }

  baeHandleToggle();
}

// scroll to top
const baeScrollTop = document.querySelector('.bae-ig-scroll-to-top');

if (baeScrollTop) {
  baeScrollTop.addEventListener('click', () => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
  })
}
