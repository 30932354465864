// if page amalgamfreie-zahnaerzte
if ( $('.page-id-2252')[0] ) {

  const $input = $('input.sbd_distance, input.sbd_location_name');

  $input.on('keyup', function(event) {
    event.preventDefault();

    // 13 == enter
    if (event.keyCode === 13) {
      sbdradius_();
    }
  });

  $('a.pd_tag_filter[data-tag=""]').text('Alle');

  $('.sbd_map').addClass('start__hidden');

  // initiate search if values in url
  (function() {
    const url_string = window.location.href;

    const url = new URL(url_string);
    const distance = url.searchParams.get("distance");
    const location_name = url.searchParams.get("location_name");

    const ln = document.getElementsByClassName('sbd_location_name')[0];
    const di = document.getElementsByClassName('sbd_distance')[0];

    // check for values
    if ( distance[0] && location_name[0] ) {
      $(ln).val(location_name);
      $(di).val(distance);
      sbdradius_();
    }

    $('a.pd_tag_filter').bind('click', function() {
        $(ln).val('');
        $(di).val('');
    });

    $('.sbd_radius_clear').bind('click', (e) => {
      e.preventDefault();
      $(ln).val('frankfurt');
      $(di).val('400');
      sbdradius_();
    })

  })();
}
