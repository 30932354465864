const header = document.querySelector('.js-iguzm-header');

if (header) {
  const offSetManager = () =>{
    if ($(window).width() > 992) {
      let yOffset = 52;
      let currYOffSet = window.pageYOffset;

      if(yOffset < currYOffSet) {
        myNavBar.add();
      }
      else if(currYOffSet < yOffset){
        myNavBar.remove();
      }
    }
  }


  const myNavBar = {
    flagAdd: true,
    elements: [],

    init: function (elements) {
      this.elements = elements;
    },

    add : function() {
      if(this.flagAdd) {
        for(let i=0; i < this.elements.length; i++) {
          document.querySelector(this.elements[i])
            .classList.add('fixed-theme');
        }
        this.flagAdd = false;
      }
    },

    remove: function() {
      for(let i=0; i < this.elements.length; i++) {
        document.querySelector(this.elements[i])
          .classList.remove('fixed-theme');
      }
      this.flagAdd = true;
    }
  };

  myNavBar.init(  [
    ".js-iguzm-header"
  ]);

  window.onscroll = function(e) {
    window.setInterval(function(){
      offSetManager();
    }, 500);
  }

  offSetManager();

}