$(this).scrollTop(0);

var $wHeight;

var banner = function() {
  $wHeight = $(window).height();

  $('html').find('.metaslider.metaslider-flex').height($wHeight*.6);
  $('.metaslider, .ms-image, .slides').height($wHeight*.6);
  $('.metaslider, .ms-image, .slides').height(600);
}

$('#content').delay(50).animate({opacity: 1}, 500);

banner();

$(window).resize(function(){
  banner();
});

$('.navbar').on('click', function() {
    var height = ($('.custom-navbar-collapse.in').length == 0) ? $(this).height() : 0;
    $('.site-content').css({
        marginTop: height
    })
});

var startPath;
var imgBackground;
$('.ms-image').each(function() {
  if (location.hostname === "ig-umwelt-zahnmedizin.test" || location.hostname === "localhost:3000") {  
    startPath = '/';
    imgBackground = $(this).children('img').attr('src');
  } else {
    startPath = '/';
    imgBackground = $(this).children('img').attr('src').split('www.ig-umwelt-zahnmedizin.de/')[1];
  }
  $(this).css({
    'background' : "url("+ startPath + imgBackground + ")"
  })
});
